













import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CbsReportReview extends Vue {
  @Prop({ default: false }) readonly visible!: boolean
  @Prop({ default: '' }) readonly src!: string

  handleClose() {
    this.$emit('update:visible', false)
  }
}
