








































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { isJingYou, getConfigurationValue } from '@lx-frontend/util/lib/modules/configuration';
import forIn from 'lodash/forIn'
import ImagePreview from '../ImagePreview.vue'
import { getInspectionSummary } from '../../common/util';

@Component({
  components: {
    ImagePreview
  }
})
export default class CarInfo extends Vue {
  @Prop({ default: () => ({})}) procedure: any
  @Prop({ default: () => ([])}) configInfo: any
  @Prop({ default: () => ({})}) inspectionInfo: any
  @Prop({ default: () => ([])}) carInfoImages: Array<any>
  @Prop({ default: () => ({})}) basicInfo: any
  @Prop({ default: () => ({})}) carDetail: any

  newPlaceList = [
    {
      id: '1',
      value: '本市'
    },
    {
      id: '2',
      value: '外迁'
    },
    {
      id: '3',
      value: '本市外迁均可'
    }
  ] // 迁往何处类型列表

  purchaseTaxList = [
    {
      id: '1',
      value: '已征收'
    },
    {
      id: '2',
      value: '未征收'
    },
    {
      id: '3',
      value: '无'
    }
  ] // 购置税类型列表

  proceduresSpendList = [
    {
      id: '1',
      value: '买方承担'
    },
    {
      id: '2',
      value: '卖方承担'
    },
    {
      id: '3',
      value: '无'
    }
  ] // 手续补办费用类型列表

  checkInfoModelOld = [
    {
      type: 'skeletonDamage',
      name: '骨架损伤',
      images: []
    },
    {
      type: 'metalRepair',
      name: '钣金修复',
      images: []
    },
    {
      type: 'appearanceDamage',
      name: '外观损伤',
      images: []
    },
    {
      type: 'waterCheck',
      name: '泡水检测',
      images: []
    },
    {
      type: 'fireCheck',
      name: '火烧检测',
      images: []
    },
    {
      type: 'inDecoration',
      name: '内饰',
      images: []
    },
    {
      type: 'machines',
      name: '机械',
      images: []
    },
    {
      type: 'electrical',
      name: '电器',
      images: []
    }
  ] // 检测信息数据结构

  checkInfoModelNew = [
    {
      type: 'skeletonDamage',
      name: '骨架',
      images: []
    },
    {
      type: 'appearanceDamage',
      name: '外观',
      images: []
    },
    {
      type: 'inDecoration',
      name: '内饰',
      images: []
    },
    {
      type: 'machines',
      name: '机械',
      images: []
    },
    {
      type: 'electrical',
      name: '电器',
      images: []
    },
    {
      type: 'fireWaterCheck',
      name: '泡水或火烧',
      images: [],
    }
  ] // 检测信息数据结构
  checkInfoData = []
  inspections = {}

  isShowPhoto = false
  detailImages = []
  currentImages = []
  currentIndex: number = 0
  showPreviewWindow: boolean = false
  inspectionSummary = {
    normal: 0,
    abnormal: 0,
    summary: ''
  }

  get isJingYouData() {
    return isJingYou(this.configInfo)
  }

  get displacement() {
    if (this.configInfo.length > 0) {
      return getConfigurationValue(this.configInfo, this.isJingYouData ? 'displacement' : 'Engine_ExhaustForFloat')
    }
    return ''
  }

  get driveWay() {
    if (this.configInfo.length > 0) {
      return getConfigurationValue(this.configInfo, this.isJingYouData ? 'drivenType' : 'Perf_DriveType')
    }
    return ''
  }

  get speedForm() {
    if (this.configInfo.length > 0) {
      return getConfigurationValue(this.configInfo, this.isJingYouData ? 'gearboxType' : 'UnderPan_TransmissionType')
    }
    return ''
  }

  formatCarImages (carImages) {
    this.currentImages = []
    carImages.forEach(item => {
      this.currentImages.push({
        ...item,
        src: item.image_url.image,
        name: item.position_name
      })
    });
  }

  formatDetailImages (urls, desc) {
    let result = [];
    urls.forEach(item => {
      result.push({
        url: item,
        describe: desc
      })
    })
    return result;
  }

  handleViewPictures (index) {
    this.formatCarImages(this.carInfoImages);
    this.isShowPhoto = false;
    this.currentIndex = index;
    this.showPreviewWindow = true;
  }

  handleLookPhoto (images) {
    this.isShowPhoto = true;
    let urls = []
    images.forEach(item => {
      urls.push({
        src: item.url,
        name: item.describe
      })
    });
    this.detailImages = urls;
    this.currentIndex = 0;
    this.showPreviewWindow = true;
  }

  getValue (id, list) {
    if (!id) return '---'

    const item = list && list.find(value => {
      return value.id === id
    })
    return item ? item.value : '---'
  }

  getCostType (type) {
    let result = []
    const item: any = this.procedure || {}
    const transferFee = item.transferFee
    const freight = item.freight
    const mentionFee = item.mentionFee
    if (transferFee === type) {
      result.push('过户费')
    }
    if (freight === type) {
      result.push('运费')
    }

    if (mentionFee === type) {
      result.push('提档费')
    }
    if (!result.length) {
      return '无'
    }
    return result.join('，')
  }

  getConfigItem (name) {
    const item = this.configInfo && this.configInfo.find(value => {
      return value.group_name === name
    })
    return (item || {}).params || []
  }

  handleInspectionsData(inspectionInfo) {
    let inspectionList = [] // 检测信息的所有数据
    const damageListList = {} // 需要展示的检测信息数据
    forIn(inspectionInfo, (value) => {
      if (typeof value === 'object') inspectionList = inspectionList.concat(value)
    })
    // 有版本号，使用新的 checkInfoModel 配置
    const checkInfoModel = inspectionInfo.version ? this.checkInfoModelNew : this.checkInfoModelOld
    // 处理需要展示的检测信息数据分类
    checkInfoModel.map(ele => {
      damageListList[ele.type] = inspectionList.filter(inspectionEle => inspectionEle.category && inspectionEle.category.includes(ele.type))
      damageListList[ele.type] = JSON.parse(JSON.stringify(damageListList[ele.type]))
      damageListList[ele.type] = damageListList[ele.type].map(a => {
        const damage = a.damageList.filter(damageElm => damageElm.category.includes(ele.type))
        if (!damage.length) {
          a.damageList = [{
            category: a.category,
            key: '1',
            selected: true,
            value: a.defaultTitle[0]
          }]
          a.images = []
          a.title = a.defaultTitle
          return { ...a }
        }
        a.title = damage.map(b => b.value)
        return { ...a }
      })
      return ele
    })
    // 深拷贝
    this.checkInfoData = JSON.parse(JSON.stringify(checkInfoModel))
    // 处理照片
    forIn(damageListList, (v, k) => {
      damageListList[k].forEach((h) => {
        if (!h.images) return;
        h.images.forEach((m) => {
          const item = this.checkInfoData.find(value => value.type === k)
          const obj = {
            url: m,
            describe: h.title.join(',')
          }
          if (h.message) {
            obj.describe += h.message
          }
          item.images.push(obj)
        })
      })
    })
    return damageListList
  }

  getFontClass (item) {
    return (item && item.damageList && item.damageList[0].key !== '1') ? 'car-font-color-red' : '';
  }

  @Watch('inspectionInfo')
  showInspectionInfo () {
    this.inspections = this.handleInspectionsData(JSON.parse(JSON.stringify(this.inspectionInfo)));
    this.inspectionSummary = getInspectionSummary(this.inspections);
  }
}
